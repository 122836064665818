* {
    marginTop: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

body, html {
    height: 100%;
    overflow-x: hidden;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    background: rgba(0, 0, 0, 0.4);
    transition: background 0.3s;
    z-index: 10;
}

/* Cambia el fondo del header cuando hay scroll */
.header.scrolled {
    background: rgba(0, 0, 0, 0.7);
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 80%;
    padding: 2rem;
    padding-top: 5rem; /* Espacio para el header */
}

.left-text {
    font-size: 1.5rem;
    max-width: 40%;
}

.right-text {
    font-size: 2.5rem;
    font-weight: bold;
    max-width: 50%;
    text-align: right;
}

.footer {
    text-align: center;
    color: white;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin-top: 9rem;
    position: relative;
    z-index: 10;
}

/* Estilo del reproductor de audio en el footer */
.audio-player {
    width: 100%;
    background-color: rgba(30, 30, 30, 0.8); /* Fondo oscuro */
    color: #FFD700; /* Color dorado */
    padding: 10px 0;
    outline: none;
    border-radius: 20px;
    margin-bottom: 10px; /* Espacio para la flecha */
}

/* Personalización para los controles (puede variar según navegador) */
.audio-player::-webkit-media-controls-panel {
    background-color: rgba(30, 30, 30, 0.8);
}

/* Estilos para la flecha */
.down-arrow {
    font-size: 2rem;
    color: white;
    margin-top: 10px;
    display: inline-block;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

/* Contenedor para centrar el visor de PDF */
.pdf-container {
    display: flex;
    justify-content: center;
    padding: 2rem;
    margin-bottom: 0.5rem;
}

.pdf-viewer {
    width: 80%;
    height: 90vh;
    border: none;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
    .content {

        flex-direction: column;
        text-align: center;
        padding-top: 6rem; /* Reduce el espacio superior en móviles */
    }

    .left-text, .right-text {
        max-width: 100%;
    }

    .right-text {
        font-size: 1.5rem;
        text-align: center;
    }

    /* Coloca el texto del artista encima del texto grande */
    .left-text {
        order: 2;
    }

    .right-text {
        order: 1;
        margin-bottom: 1rem;
    }
    .pdf-viewer {
        width: 100%;
        height: 90vh;
        border: none;
    }
}
